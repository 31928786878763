/* CSS Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, html {
  height: 100%;
  font-family: 'Poppins', sans-serif;
  background-color: #f0f4f8;
}

/* General layout for App */
.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

/* Header section with softer blue */
.header {
  background-color: #4f6dab; /* Softer blue */
  padding: 1rem;
  text-align: left;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.site-info {
  display: flex;
  flex-direction: column;
}

.site-title {
  font-size: 2rem;
  color: white;
  font-weight: 600;
  margin-left: 1rem;
}

.tagline {
  font-size: 1rem;
  color: #d1d5db;
  margin-left: 1rem;
  margin-top: 0.25rem;
}

/* Main content section */
.main-content {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e0e7ff;
}

.main-container {
  text-align: center;
  padding: 2rem;
}

.main-message {
  font-size: 3rem;
  color: #1e3a8a;
  font-weight: 600;
}

.description {
  font-size: 1.5rem;
  color: #333;
  margin-top: 1rem;
  font-family: 'Roboto', sans-serif;
}

/* Footer section */
.footer {
  background-color: #3b82f6;
  color: white;
  padding: 1rem;
  text-align: center;
}

.footer p {
  margin: 0;
}

/* Navigation */
.nav-link {
  color: white;
  font-size: 1rem;
  margin-left: 2rem;
  text-decoration: none;
}

.nav-link:hover {
  text-decoration: underline;
}
